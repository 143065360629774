import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { currencyFormat } from '../../helpers';

const Price = ({
  value,
  currency,
  defaultCurrency,
  intl: { formatNumber },
  prefix,
  parenthesis,
  showCurrency,
}) => {
  const price = formatNumber(value, {
    ...currencyFormat(showCurrency ? currency || defaultCurrency : null),
  });
  return prefix
    ? `${prefix} ${parenthesis ? '(' : ''}${price}${parenthesis ? ')' : ''}`
    : `${parenthesis ? '(' : ''}${price}${parenthesis ? ')' : ''}`;
};

Price.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string,
  prefix: PropTypes.string,
  parenthesis: PropTypes.bool,
  showCurrency: PropTypes.bool,
};

Price.defaultProps = {
  parenthesis: false,
  currency: undefined,
  prefix: undefined,
  showCurrency: true,
};

const mapState = state => ({
  defaultCurrency: state.intl.currency,
});

export default compose(injectIntl, connect(mapState))(Price);
