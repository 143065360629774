/* eslint-disable css-modules/no-unused-class */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import carouselStyles from 'pure-react-carousel/dist/react-carousel.es.css';
import s from './Slider.scss';
import Icon from '../Icon';
import { ICONS } from '../../constants';

const defaultSlideIndex = (currentSlide, childrenLength) => {
  if (currentSlide) {
    return currentSlide < childrenLength ? currentSlide : childrenLength;
  }
  return 0;
};

const CarouselSlider = ({
  children,
  naturalSlideHeight,
  naturalSlideWidth,
  visibleSlides,
  tabIndex,
  tag,
  trayTag,
  className,
  onFocus,
  arrows,
  hoverArrows,
  largeArrows,
  darkBackground,
  dots,
  responsive,
  isPlaying,
  hoverType, // box-shadow
  infinite,
  currentSlide,
  forceSlideHeight,
  isIntrinsicHeight,
}) => {
  const childrenLength = children ? children.length : 0;
  const [slidePosition, setSlidePostion] = useState(
    defaultSlideIndex(currentSlide, childrenLength),
  );

  return (
    <CarouselProvider
      className={cx(
        s.carousel,
        className,
        hoverType && s[`hover-type--${hoverType}`],
      )}
      naturalSlideWidth={naturalSlideWidth}
      naturalSlideHeight={naturalSlideHeight}
      totalSlides={(children && children.length) || 0}
      visibleSlides={visibleSlides}
      dragEnabled={false}
      tag={tag}
      isPlaying={isPlaying}
      infinite={infinite}
      currentSlide={currentSlide}
      isIntrinsicHeight={isIntrinsicHeight}
    >
      <Slider
        className={s['tray-warp-wrap']}
        classNameTrayWrap={s['tray-wrap']}
        trayTag={trayTag}
      >
        {children &&
          children.map((child, index) => (
            <Slide
              key={index.toString()}
              index={index}
              tabIndex={tabIndex}
              className={cx(
                s.slide,
                arrows && s.arrows,
                !responsive && s['slide--auto-height'],
              )}
              style={forceSlideHeight && { height: `${naturalSlideHeight}px` }}
              innerClassName={s['slide-inner']}
              onFocus={onFocus}
            >
              {child}
            </Slide>
          ))}
      </Slider>
      {dots && <DotGroup className={s.dots} />}
      {arrows && childrenLength > visibleSlides && (
        <Fragment>
          {slidePosition > 0 && (
            <div
              className={cx(
                s.buttonContainer,
                largeArrows ? s.backward__largeArrow : s.backward,
                hoverArrows && s.buttonContainer__hover,
                darkBackground && !largeArrows && s.buttonContainer__dark,
              )}
            >
              <ButtonBack
                className={cx(
                  s.button,
                  largeArrows ? s.button__back__largeArrow : s.button__back,
                )}
                onClick={() => setSlidePostion(slidePosition - 1)}
              >
                {!largeArrows && <Icon name={ICONS.CHEVRON_LEFT} />}
              </ButtonBack>
            </div>
          )}
          {slidePosition + visibleSlides < childrenLength && (
            <div
              className={cx(
                s.buttonContainer,
                largeArrows ? s.forward__largeArrow : s.forward,
                hoverArrows && s.buttonContainer__hover,
                darkBackground && !largeArrows && s.buttonContainer__dark,
              )}
            >
              <ButtonNext
                className={cx(
                  s.button,
                  largeArrows ? s.button__next__largeArrow : s.button__next,
                )}
                onClick={() => setSlidePostion(slidePosition + 1)}
              >
                {!largeArrows && <Icon name={ICONS.CHEVRON_RIGHT} />}
              </ButtonNext>
            </div>
          )}
        </Fragment>
      )}
    </CarouselProvider>
  );
};

CarouselSlider.propTypes = {
  children: PropTypes.node.isRequired,
  naturalSlideHeight: PropTypes.number.isRequired,
  naturalSlideWidth: PropTypes.number,
  visibleSlides: PropTypes.number.isRequired,
  tabIndex: PropTypes.string,
  tag: PropTypes.string,
  trayTag: PropTypes.string,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  arrows: PropTypes.bool,
  hoverArrows: PropTypes.bool,
  largeArrows: PropTypes.bool,
  darkBackground: PropTypes.bool,
  dots: PropTypes.bool,
  responsive: PropTypes.bool,
  isPlaying: PropTypes.bool,
  hoverType: PropTypes.string,
  infinite: PropTypes.bool,
  currentSlide: PropTypes.number,
  forceSlideHeight: PropTypes.bool,
  isIntrinsicHeight: PropTypes.bool,
};

CarouselSlider.defaultProps = {
  naturalSlideWidth: 100,
  tabIndex: null,
  trayTag: 'ul',
  tag: 'div',
  onFocus: null,
  arrows: true,
  hoverArrows: false,
  largeArrows: false,
  darkBackground: false,
  dots: false,
  responsive: true,
  className: undefined,
  hoverType: '',
  isPlaying: false,
  infinite: false,
  currentSlide: null,
  forceSlideHeight: false,
  isIntrinsicHeight: false,
};

export default withStyles(carouselStyles, s)(CarouselSlider);
