import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ACCOUNT_TYPE,
  ACCOUNT_CREATE_PATH,
  SELECT_DEPARTMENT_PATH,
  BLOCKED_DEPARTMENT_PATH,
} from '../constants';
import { getAccessToken } from '../helpers/token';
import { redirectTo, redirectToLogin } from '../actions/config';
import Loading from '../components/Loading';
import history from '../history';

export default ({ modesAllowed, userRequired } = {}) => WrappedComponent => {
  const Component = ({
    dispatch,
    user,
    mode,
    userLoaded,
    modeLoaded,
    redirecting,
    ...rest
  }) => {
    useEffect(() => {
      const { pathname } = history.location;
      if (!redirecting) {
        if (
          mode === ACCOUNT_TYPE.BLOCKED &&
          !pathname.endsWith(BLOCKED_DEPARTMENT_PATH)
        ) {
          dispatch(
            redirectTo({
              pathname: BLOCKED_DEPARTMENT_PATH,
            }),
          );
          return;
        }
        if (modeLoaded && mode === ACCOUNT_TYPE.B2C) {
          const redirectPath =
            user.accounts.length > 1
              ? SELECT_DEPARTMENT_PATH
              : ACCOUNT_CREATE_PATH;
          if (!pathname.endsWith(redirectPath)) {
            dispatch(
              redirectTo({
                pathname: redirectPath,
                push: true,
              }),
            );
            return;
          }
        }
        if (userRequired && userLoaded && !user) {
          dispatch(redirectToLogin());
          return;
        }
        if (modeLoaded && modesAllowed && !modesAllowed.includes(mode)) {
          dispatch(redirectToLogin());
        }
      }
    }, [mode, userLoaded, modeLoaded, redirecting, dispatch, user]);

    if (
      redirecting ||
      (userRequired && !userLoaded) ||
      (modesAllowed && !modeLoaded)
    ) {
      return <Loading />;
    }

    return (
      <WrappedComponent
        user={user}
        mode={mode}
        userLoaded={userLoaded}
        modeLoaded={modeLoaded}
        {...rest}
      />
    );
  };

  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  Component.displayName = `withUser(${displayName})`;

  Component.propTypes = {
    user: PropTypes.shape(),
    mode: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    userLoaded: PropTypes.bool.isRequired,
    modeLoaded: PropTypes.bool.isRequired,
    redirecting: PropTypes.bool.isRequired,
  };

  Component.defaultProps = {
    user: null,
  };

  const mapState = ({
    user: { account, accounts, mode } = {},
    loggedinUser: { user } = {},
    loading: { initialAuth, initialBasket, redirecting },
  }) => {
    const accessToken = getAccessToken();

    if (!accessToken || !user) {
      return {
        user: null,
        mode,
        modeLoaded: initialAuth !== null && initialBasket !== null,
        userLoaded: initialAuth !== null,
        redirecting,
      };
    }
    const type = account ? ACCOUNT_TYPE.B2B : ACCOUNT_TYPE.B2C;
    return {
      user: {
        name: account && account.name ? account.name : user.name,
        company:
          account && account.department && account.department.name
            ? account.department.name
            : '',

        type,
        account,
        departmentId:
          account && account.department ? account.department.id : undefined,
        approvedForInvoice:
          (account &&
            account.department &&
            account.department.approvedForInvoice) ||
          false,
        accounts,
      },
      mode,
      modeLoaded: initialAuth !== null && initialBasket !== null,
      userLoaded: initialAuth !== null,
      redirecting,
    };
  };

  return connect(mapState)(Component);
};
