// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2QZG7+._2QZG7{border-top:1px solid #dfe3e8}._22wjG{cursor:pointer;width:100%;text-align:left;border:none;position:relative;padding:16px 48px 16px 16px}._1hYMK{margin-top:16px;padding:0 16px 16px}._2Rlwr ul{margin:1em 0;padding-left:2em;list-style-type:disc}._2Rlwr a{color:#2e3b45;font-weight:bold}._2Rlwr a:hover{color:#00a1df}._22wjG>*:last-child,._1hYMK>*:last-child{margin-bottom:0}.q7Qzp{display:block;position:absolute;top:0;right:0;padding:16px}.q7Qzp svg{width:16px;height:16px}[aria-expanded='true'] .q7Qzp{transform:rotate(180deg)}\n", ""]);
// Exports
exports.locals = {
	"item": "_2QZG7",
	"title": "_22wjG",
	"body": "_1hYMK",
	"markdown": "_2Rlwr",
	"arrow": "q7Qzp"
};
module.exports = exports;
