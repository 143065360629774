import React, { isValidElement } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { compose } from 'redux';
import Icon from '../Icon';
import s from './accordion.scss';
import { ICONS } from '../../constants';
import { createMarkup, physicalProductFunc } from '../../helpers';

export default compose(withStyles(s))(
  ({ items, boldTitles = false, className, products = null, ...props }) => {
    return (
      <Accordion
        className={cx(className && className)}
        allowZeroExpanded
        allowMultipleExpanded
        {...props}
      >
        {items &&
          items.map(({ key, title, body, expanded }) => {
            return (
              key &&
              physicalProductFunc(products, key) && (
                <AccordionItem key={key} className={s.item} expanded={expanded}>
                  <AccordionItemHeading
                    className={s.title}
                    style={boldTitles ? { fontWeight: 'bold' } : {}}
                  >
                    <AccordionItemButton>
                      {title}
                      <div className={s.arrow} role="presentation">
                        <Icon name={ICONS.CHEVRON_DOWN} />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={s.body}>
                    {isValidElement(body) ? (
                      body
                    ) : (
                      <div
                        className={s.markdown}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={createMarkup(body)}
                      />
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              )
            );
          })}
      </Accordion>
    );
  },
);
